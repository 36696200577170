

import { Vue, Component, Prop } from 'vue-property-decorator'
import { Product } from '../../types/supplier'
import { Page } from '../../types/common'

@Component
export default class SupplierProduct extends Vue {
  @Prop() readonly id!: string;

  page = 1
  size = 10
  total = 0
  loading = false
  tableData: Product[] = []

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<Product>>(this.$apis.supplier.productByPage, {
      page: this.page,
      size: this.size,
      supplierId: this.id
    }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  deleteProduct (productId: string) {
    this.$confirm('提示', '确认删除该条数据吗？').then(() => {
      this.$axios.post(this.$apis.supplier.delProductById, {
        productId
      }).then(() => {
        this.loadData()
      })
    })
  }
}
